// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Button } from 'react-bootstrap';
// import { CSVLink } from 'react-csv';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
// import { useNavigate } from "react-router-dom";
// import toast, { Toaster } from "react-hot-toast";
// import useAuthCheck from "../hooks/useAuthCheck";

// const Enquiry1 = () => {
//   const [formDataList, setFormDataList] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemsPerPage] = useState(10);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   // Check for authentication
//   useAuthCheck();
//   const navigate = useNavigate();

//   // Retrieve user data from session storage
//   const userData = JSON.parse(sessionStorage.getItem("userData"));
//   const username = userData?.username;

//   // Fetch data from the API
//   const fetchData = async () => {
//     try {
//       const response = await axios.get(process.env.REACT_APP_API_URL_FORM1);
//       if (Array.isArray(response.data)) {
//         setFormDataList(response.data);
//       } else {
//         console.error('Data fetched is not an array:', response.data);
//         toast.error("Error fetching data");
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       toast.error("Error fetching data");
//     }
//   };

//   // Delete a record from the API
//   const deleteRecord = async (id) => {
//     try {
//       await axios.delete(`${process.env.REACT_APP_API_URL_FORM1}?id=${id}`);
//       toast.success("Record deleted successfully");
//       fetchData(); // Refresh the list after deletion
//     } catch (error) {
//       console.error('Error deleting data:', error);
//       toast.error("Error deleting record");
//     }
//   };

//   // Prepare data for CSV export
//   const exportCSV = () => {
//     const csvData = formDataList.map(formData => ({
//       'First Name': formData.firstName,
//       'Last Name': formData.lastName,
//       'Email': formData.email,
//       'Contact': formData.contact,
//       'Position': formData.position,
//       'File Link': formData.file,
//       'Message': formData.message,
//       'Created At': formData.created_at,
//     }));
//     return csvData;
//   };

//   // Prepare data for PDF export
//   const exportPDF = () => {
//     const unit = 'pt';
//     const size = 'A4';
//     const orientation = 'landscape';
//     const doc = new jsPDF(orientation, unit, size);

//     const headers = [['First Name', 'Last Name', 'Email', 'Contact', 'Position', 'File Link', 'Message', 'Created At']];
//     const dataRows = formDataList.map(formData => [
//       formData.firstName,
//       formData.lastName,
//       formData.email,
//       formData.contact,
//       formData.position,
//       formData.file,
//       formData.message,
//       formData.created_at,
//     ]);

//     doc.autoTable({
//       head: headers,
//       body: dataRows,
//     });

//     doc.save('formData.pdf');
//   };

//   // Handle page change
//   const handlePageChange = (pageNumber) => {
//     setCurrentPage(pageNumber);
//   };

//   // Paginate the items
//   const paginate = (items, pageNumber, itemsPerPage) => {
//     const startIndex = (pageNumber - 1) * itemsPerPage;
//     return items.slice(startIndex, startIndex + itemsPerPage);
//   };

//   const paginatedFormDataList = paginate(formDataList, currentPage, itemsPerPage);
//   const totalPages = Math.ceil(formDataList.length / itemsPerPage);

//   return (
//     <div className="container mx-auto mt-5 p-4">
//       <Toaster />
//       <div className="flex flex-col md:flex-row items-center justify-between w-full p-6 bg-white shadow-lg">
//         <h1 className="text-2xl font-bold text-gray-800">Welcome, {username}!</h1>
//         <button
//           className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
//           onClick={() => {
//             toast.success("You have successfully logged out!");
//             sessionStorage.removeItem("loggedIn");
//             sessionStorage.removeItem("userData");
//             navigate("/");
//           }}
//         >
//           Logout
//         </button>
//       </div>
//       <h2 className="mb-4 text-2xl font-semibold text-center">Enquiry Data</h2>
//       <div className="mb-3 flex space-x-2 justify-center">
//         <CSVLink
//           data={exportCSV()}
//           filename={'formData.csv'}
//           className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           target="_blank"
//         >
//           Export CSV
//         </CSVLink>
//         <Button
//           className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
//           onClick={exportPDF}
//         >
//           Export PDF
//         </Button>
//       </div>
//       <div className="overflow-x-auto">
//         <table className="table-auto w-full bg-white shadow-md rounded my-6">
//           <thead className="bg-gray-200">
//             <tr>
//               <th className="py-2 px-4 border-b">First Name</th>
//               <th className="py-2 px-4 border-b">Last Name</th>
//               <th className="py-2 px-4 border-b">Email</th>
//               <th className="py-2 px-4 border-b">Contact</th>
//               <th className="py-2 px-4 border-b">Position</th>
//               <th className="py-2 px-4 border-b">File Link</th>
//               <th className="py-2 px-4 border-b">Message</th>
//               <th className="py-2 px-4 border-b">Created At</th>
//               <th className="py-2 px-4 border-b">Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {paginatedFormDataList.map((formData, index) => (
//               <tr key={index} className="text-center">
//                 <td className="py-2 px-4 border-b">{formData.firstName}</td>
//                 <td className="py-2 px-4 border-b">{formData.lastName}</td>
//                 <td className="py-2 px-4 border-b">{formData.email}</td>
//                 <td className="py-2 px-4 border-b">{formData.contact}</td>
//                 <td className="py-2 px-4 border-b">{formData.position}</td>
//                 <td className="py-2 px-4 border-b">
//                   <a href={`uploads/${formData.file}`} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
//                     Download
//                   </a>
//                 </td>
//                 <td className="py-2 px-4 border-b">{formData.message}</td>
//                 <td className="py-2 px-4 border-b">{formData.created_at}</td>
//                 <td className="py-2 px-4 border-b">
//                   <button
//                     onClick={() => deleteRecord(formData.id)}
//                     className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
//                   >
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//       <div className="flex justify-center mt-4">
//         {Array.from({ length: totalPages }, (_, index) => (
//           <button
//             key={index}
//             onClick={() => handlePageChange(index + 1)}
//             className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
//           >
//             {index + 1}
//           </button>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Enquiry1;





//new
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import useAuthCheck from "../hooks/useAuthCheck";

const Enquiry1 = () => {
  const [formDataList, setFormDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    fetchData();
  }, []);

  // Check for authentication
  useAuthCheck();
  const navigate = useNavigate();

  // Retrieve user data from session storage
  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const username = userData?.username;

  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_URL_FORM1);
      if (Array.isArray(response.data)) {
        setFormDataList(response.data);
      } else {
        console.error('Data fetched is not an array:', response.data);
        toast.error("Error fetching data");
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error("Error fetching data");
    }
  };

  // Delete a record from the API
  const deleteRecord = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL_FORM1}?id=${id}`);
      toast.success("Record deleted successfully");
      fetchData(); // Refresh the list after deletion
    } catch (error) {
      console.error('Error deleting data:', error);
      toast.error("Error deleting record");
    }
  };

  // Prepare data for CSV export
  const exportCSV = () => {
    const csvData = formDataList.map(formData => ({
      'First Name': formData.firstName,
      'Last Name': formData.lastName,
      'Email': formData.email,
      'Contact': formData.contact,
      'Position': formData.position,
      'CV': formData.file,
      'Message': formData.message,
      'Created At': formData.created_at,
    }));
    return csvData;
  };

  // Prepare data for PDF export
  const exportPDF = () => {
    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const doc = new jsPDF(orientation, unit, size);

    const headers = [['First Name', 'Last Name', 'Email', 'Contact', 'Position', 'File Link', 'Message', 'Created At']];
    const dataRows = formDataList.map(formData => [
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.contact,
      formData.position,
      formData.file,
      formData.message,
      formData.created_at,
    ]);

    doc.autoTable({
      head: headers,
      body: dataRows,
    });

    doc.save('formData.pdf');
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Paginate the items
  const paginate = (items, pageNumber, itemsPerPage) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const paginatedFormDataList = paginate(formDataList, currentPage, itemsPerPage);
  const totalPages = Math.ceil(formDataList.length / itemsPerPage);

  return (
    <div className="container mx-auto mt-5 p-4">
      <Toaster />
      <div className="flex flex-col md:flex-row items-center justify-between w-full p-6 bg-white shadow-lg">
        <h1 className="text-2xl font-bold text-gray-800">Welcome, {username}!</h1>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => {
            toast.success("You have successfully logged out!");
            sessionStorage.removeItem("loggedIn");
            sessionStorage.removeItem("userData");
            navigate("/");
          }}
        >
          Logout
        </button>
      </div>
      <h2 className="mb-4 text-2xl font-semibold text-center">Enquiry Data</h2>
      <div className="mb-3 flex space-x-2 justify-center">
        <CSVLink
          data={exportCSV()}
          filename={'formData.csv'}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          target="_blank"
        >
          Export CSV
        </CSVLink>
        <Button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={exportPDF}
        >
          Export PDF
        </Button>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full bg-white shadow-md rounded my-6">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4 border-b">First Name</th>
              <th className="py-2 px-4 border-b">Last Name</th>
              <th className="py-2 px-4 border-b">Email</th>
              <th className="py-2 px-4 border-b">Contact</th>
              <th className="py-2 px-4 border-b">Position</th>
              <th className="py-2 px-4 border-b">CV</th>
              <th className="py-2 px-4 border-b">Message</th>
              <th className="py-2 px-4 border-b">Created At</th>
              <th className="py-2 px-4 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {paginatedFormDataList.map((formData, index) => (
              <tr key={index} className="text-center">
                <td className="py-2 px-4 border-b">{formData.firstName}</td>
                <td className="py-2 px-4 border-b">{formData.lastName}</td>
                <td className="py-2 px-4 border-b">{formData.email}</td>
                <td className="py-2 px-4 border-b">{formData.contact}</td>
                <td className="py-2 px-4 border-b">{formData.position}</td>
                <td className="py-2 px-4 border-b">
                  <a href={formData.file} className="text-blue-500 underline" target="_blank" rel="noopener noreferrer">
                    Download
                  </a>
                </td>
                <td className="py-2 px-4 border-b">{formData.message}</td>
                <td className="py-2 px-4 border-b">{formData.created_at}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    onClick={() => deleteRecord(formData.id)}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Enquiry1;


// src/firebaseConfig.js
const firebaseConfig = {
  apiKey: "AIzaSyCFu6X3M9sdFUPQE2KSxHo9uaFOvRhmfzU",
  authDomain: "devi-aviation.firebaseapp.com",
  projectId: "devi-aviation",
  storageBucket: "devi-aviation.appspot.com",
  messagingSenderId: "895991598554",
  appId: "1:895991598554:web:f5251cb19582fd3d5044e3"
};

 export default firebaseConfig;








//VERSION 2.00

// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//     authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//     projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

// export default firebaseConfig;


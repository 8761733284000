import React from 'react';
import './App.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PhoneAuth from './components/PhoneAuth';
import Login from './components/PhoneLogin';
// import Home from './components/Home';
import Enquiry1 from './components/Career';
import Enquiry2 from './components/Courses';
import User from './components/User';
import Logout from './components/Logout';
// import Support from './components/support';
import Layout from './components/Layout';
import PrivateRoute from './components/PrivateRoute';
import firebaseConfig from './firebaseConfig';

// import CareerPage from './components/devicareer';
// import Courses from './components/devicourses';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/PhoneAuth" element={<PhoneAuth auth={firebase.auth()} />} />
          <Route path="/" element={<Login />} />
          {/* <Route path="/devicareer" element={<CareerPage />} />
          <Route path="/devicourses" element={<Courses />} /> */}
          {/* <Route path="/support" element={<Support />} /> */}
          <Route element={<Layout />}>
            <Route path="/Career" element={<PrivateRoute component={Enquiry1} />} />
            <Route path="/Courses" element={<PrivateRoute component={Enquiry2} />} />
            <Route path="/User" element={<PrivateRoute component={User} />} />
            {/* <Route path="/PhoneAuth" element={<PrivateRoute component={PhoneAuth} auth={firebase.auth()} />} /> */}
            <Route path="/Logout" element={<Logout />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;

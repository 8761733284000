// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';

// const Navbar = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//   const [drawerOpen, setDrawerOpen] = useState(false);

//   const toggleDrawer = (open) => () => {
//     setDrawerOpen(open);
//   };

//   const menuItems = [
//     { text: 'Enquiry', path: '/Enquiry-Form' },
//     { text: 'Logout', path: '/Logout' },
//   ];

//   return (
//     <div>
//       <AppBar position="static">
//         <Toolbar>
//           {isMobile && (
//             <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
//               <MenuIcon />
//             </IconButton>
//           )}
//           <Typography variant="h6" style={{ flexGrow: 1 }}>
//             ADMIN PANNEL
//           </Typography>
//           {!isMobile && menuItems.map((item) => (
//             <Link key={item.text} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
//               <Button color="inherit">{item.text}</Button>
//             </Link>
//           ))}
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         <List style={{ width: 250 }}>
//           {menuItems.map((item) => (
//             <Link key={item.text} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
//               <ListItem button onClick={toggleDrawer(false)}>
//                 <ListItemText primary={item.text} />
//               </ListItem>
//             </Link>
//           ))}
//         </List>
//       </Drawer>
//     </div>
//   );
// };

// export default Navbar;



//v-2.00
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// // import logo from './logo.png'; // make sure to replace this with the actual path to your logo

// const Navbar = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));
//   const [drawerOpen, setDrawerOpen] = useState(false);

//   const toggleDrawer = (open) => () => {
//     setDrawerOpen(open);
//   };

//   const menuItems = [
//     { text: 'Enquiry', path: '/Enquiry-Form' },
//     { text: 'User', path: '/User' },
//     { text: 'Logout', path: '/Logout' },
//     { text: 'Support', path: '/Support' }, // Added Support button
//   ];

//   return (
//     <div>
//       <AppBar position="static">
//         <Toolbar>
//           {isMobile && (
//             <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
//               <MenuIcon />
//             </IconButton>
//           )}
//           {/* <img src={logo} alt="Brand Logo" style={{ height: '50px', marginRight: '16px' }} />  */}
//           <Typography variant="h6" style={{ flexGrow: 1 }}>
//             ADMIN PANEL
//           </Typography>
//           {!isMobile && menuItems.map((item) => (
//             <Link key={item.text} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
//               <Button color="inherit" style={{ margin: '0 8px', transition: 'color 0.3s' }} 
//                 onMouseEnter={(e) => e.target.style.color = theme.palette.primary.main}
//                 onMouseLeave={(e) => e.target.style.color = 'inherit'}>
//                 {item.text}
//               </Button>
//             </Link>
//           ))}
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         <List style={{ width: 250 }}>
//           {menuItems.map((item) => (
//             <Link key={item.text} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
//               <ListItem button onClick={toggleDrawer(false)}>
//                 <ListItemText primary={item.text} />
//               </ListItem>
//             </Link>
//           ))}
//         </List>
//       </Drawer>
//     </div>
//   );
// };

// export default Navbar;





//v 3.00
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme, createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// import logo from './logo.png'; // make sure to replace this with the actual path to your logo

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bb86fc',
    },
    background: {
      default: '#121212',
      paper: '#1d1d1d',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bb86fc',
    },
  },
});

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    { text: 'Career', path: '/Career' },
    { text: 'Courses', path: '/Courses' },
    { text: 'User', path: '/User' },
    // { text: 'Support', path: '/support' },
    { text: 'signup', path: '/PhoneAuth' },
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <div>
        <AppBar position="static">
          <Toolbar>
            {isMobile && (
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            )}
            {/* <img src={logo} alt="Brand Logo" style={{ height: '50px', marginRight: '16px' }} /> */}
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              ADMIN PANNEL
            </Typography>
            {!isMobile && menuItems.map((item) => (
              <Link key={item.text} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Button color="inherit" style={{ margin: '0 8px', transition: 'color 0.3s' }} 
                  onMouseEnter={(e) => e.target.style.color = theme.palette.primary.main}
                  onMouseLeave={(e) => e.target.style.color = 'inherit'}>
                  {item.text}
                </Button>
              </Link>
            ))}
          </Toolbar>
        </AppBar>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List style={{ width: 250 }}>
            {menuItems.map((item) => (
              <Link key={item.text} to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem Button onClick={toggleDrawer(false)}>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
          </List>
        </Drawer>
      </div>
    </ThemeProvider>
  );
};

export default Navbar;


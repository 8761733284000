// src/components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthCheck from '../hooks/useAuthCheck';

const PrivateRoute = ({ component: Component }) => {
  useAuthCheck();

  const loggedIn = sessionStorage.getItem("loggedIn");

  return loggedIn ? <Component /> : <Navigate to="/Career" />;
};

export default PrivateRoute;
